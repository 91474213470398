export default [
  {
    title: 'Front End',
    points: ['Typescript/Javascript', 'React', 'NextJS', 'CSS3/SASS'],
    subpoints: ['Redux', 'React Native', 'Vue', 'GSAP'],
  },
  {
    title: 'Back End',
    points: ['Node.js', 'PHP', 'SQL', 'Python'],
    subpoints: ['PostgresQL', 'MongoDB', 'ExpressJS'],
  },
  {
    title: 'Other',
    points: ['Webpack', 'GIT', 'Styled Components', 'Wordpress'],
    subpoints: ['GraphQL', 'Storybook', 'Electron', 'Apache Cordova for iOS'],
  },
];
