// import { useImage } from 'components/hooks';

// export const walkingCatStart = [
//   useImage(require('src/assets/images/walking-cat/cat11.png')),
//   useImage(require('src/assets/images/walking-cat/cat10.png')),
//   useImage(require('src/assets/images/walking-cat/cat9.png')),
//   useImage(require('src/assets/images/walking-cat/cat8.png')),
//   useImage(require('src/assets/images/walking-cat/cat7.png')),
//   useImage(require('src/assets/images/walking-cat/cat6.png')),
//   useImage(require('src/assets/images/walking-cat/cat5.png')),
//   useImage(require('src/assets/images/walking-cat/cat1.png')),
//   useImage(require('src/assets/images/walking-cat/cat2.png')),
//   useImage(require('src/assets/images/walking-cat/cat3.png')),
//   useImage(require('src/assets/images/walking-cat/cat4.png')),
//   useImage(require('src/assets/images/walking-cat/cat5.png')),
//   useImage(require('src/assets/images/walking-cat/cat6.png')),
//   useImage(require('src/assets/images/walking-cat/cat1.png')),
//   useImage(require('src/assets/images/walking-cat/cat2.png')),
//   useImage(require('src/assets/images/walking-cat/cat3.png')),
//   useImage(require('src/assets/images/walking-cat/cat4.png')),
//   useImage(require('src/assets/images/walking-cat/cat5.png')),
//   useImage(require('src/assets/images/walking-cat/cat6.png')),
// ];

export const walkingCatEnd = [
  require('src/assets/images/walking-cat/cat1.png'),
  require('src/assets/images/walking-cat/cat2.png'),
  require('src/assets/images/walking-cat/cat3.png'),
  require('src/assets/images/walking-cat/cat4.png'),
  require('src/assets/images/walking-cat/cat5.png'),
  require('src/assets/images/walking-cat/cat6.png'),
  require('src/assets/images/walking-cat/cat7.png'),
  require('src/assets/images/walking-cat/cat8.png'),
  require('src/assets/images/walking-cat/cat9.png'),
  require('src/assets/images/walking-cat/cat10.png'),
  require('src/assets/images/walking-cat/cat11.png'),
  require('src/assets/images/walking-cat/cat12.png'),
  require('src/assets/images/walking-cat/cat13.png'),
  require('src/assets/images/walking-cat/cat14.png'),
  require('src/assets/images/walking-cat/cat15.png'),
  require('src/assets/images/walking-cat/cat16.png'),
  require('src/assets/images/walking-cat/cat17.png'),
  require('src/assets/images/walking-cat/cat18.png'),
  require('src/assets/images/walking-cat/cat19.png'),
  require('src/assets/images/walking-cat/cat20.png'),
  require('src/assets/images/walking-cat/cat21.png'),
  require('src/assets/images/walking-cat/cat22.png'),
  require('src/assets/images/walking-cat/cat23.png'),
  require('src/assets/images/walking-cat/cat24.png'),
  require('src/assets/images/walking-cat/cat25.png'),
  require('src/assets/images/walking-cat/cat26.png'),
  require('src/assets/images/walking-cat/cat27.png'),
  require('src/assets/images/walking-cat/cat28.png'),
  require('src/assets/images/walking-cat/cat29.png'),
  require('src/assets/images/walking-cat/cat30.png'),
  require('src/assets/images/walking-cat/cat31.png'),
  require('src/assets/images/walking-cat/cat32.png'),
  require('src/assets/images/walking-cat/cat33.png'),
  require('src/assets/images/walking-cat/cat34.png'),
  require('src/assets/images/walking-cat/cat35.png'),
  require('src/assets/images/walking-cat/cat36.png'),
  require('src/assets/images/walking-cat/cat37.png'),
  require('src/assets/images/walking-cat/cat38.png'),
  require('src/assets/images/walking-cat/cat39.png'),
  require('src/assets/images/walking-cat/cat40.png'),
  require('src/assets/images/walking-cat/cat41.png'),
];
